export default {
  data() {
    return {
      name: '',
    };
  },

  mounted() {
    // console.log(`Auth:${this.$auth.check()}`);

    if (!this.$auth.check()) {
      const defaultName = '';

      this.name = prompt('Enter your name : ', defaultName);

      this.login();
    }
  },

  methods: {
    login() {
      if (!this.$auth.check()) {
        this.$auth.login({
          data: {
            name: this.name,
          },
          rememberMe: true,
          fetchUser: true,
          redirect: false,
        });
      }
    },
  },
};
