<template>
  <div v-if="$auth.check()">
    <SearchPanel v-if="isDev() || isDJ()" />
    <Webamp
      v-if="songs.length > 0"
      :initial-songs="songs"
      :config="config"
    />
    <MediaButtons v-if="isDev() || isDJ()" />
    <QR v-if="isDev() || isDJ()" />
    <UserList v-if="isDJ() || isDisco()" />
    <UserCount v-if="isDJ() || isDisco()" />
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

import auth from '@/mixins/auth';
import echo from '@/mixins/echo';
import events from '@/mixins/events';

export default {
  components: {
    SearchPanel: () => import('@/components/SearchPanel'),
    Webamp: () => import('@/components/Webamp'),
    MediaButtons: () => import('@/components/MediaButtons'),
    QR: () => import('@/components/QR'),
    UserList: () => import('@/components/UserList'),
    UserCount: () => import('@/components/UserCount'),
  },

  mixins: [
    auth, //
    echo, //
    events, //
  ],

  computed: {
    ...sync([
      'webamp/playlist', //
    ]),

    config() {
      return {
        show: {
          eq: false,
          playlist: true,
          milkdrop: false,
        },
      };
    },
  },

  mounted() {
    if (this.isDJ()) {
      if (this.playlist === '') {
        this.$store.set('webamp/playlist', this.$config.defaultPlaylist);
      }

      Bus.$emit('songs:fetch', {
        playlist: this.playlist,
      });
    } else if (this.$route.hash !== '') {
      Bus.$emit('songs:fetch', {
        hash: this.$route.hash,
      });
    }
  },
};
</script>
