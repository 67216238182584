import _ from 'lodash';
import { sync } from 'vuex-pathify';

export default {

  computed: {
    ...sync([
      'online/users', //
      'api/params', //
    ]),
        
    isAuthenticated() {
      return this.$auth.check();
    },

    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    isAuthenticated: {
      handler() {
        if(this.$auth.check()) {
          this.registerAuthorizedEchoListeners();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    // console.log('joining disco channel');

    this.registerPublicEchoListeners();
  },

  methods: {
    registerPublicEchoListeners() {
      // console.log('registerPublicEchoListeners');
      if(!this.isDJ) {
        window.Echo.channel('disco').listen('.DiscoWidespreadEvent', (event) => {

          // if(!isDisco()) {
          //   this.$router.push({
          //     name: 'disco',
          //   }, () => {
          //     setTimeout(() => {
          //       // console.log('route.onComplete');
          //       this.echoEvent(event);
          //     }, 500);
          //   });
          // }
    
          this.echoEvent(event);
        });
      }
    },

    registerAuthorizedEchoListeners() {
      if(this.$auth.check()) {
        // console.log('registerAuthorizedEchoListeners');

        window.Echo.join('online')
        .here((users) => {
          // console.log(`online.here.users`, users);

          this.users = users;

          _.each(this.users, user => {
            // console.log(`${user.name} is online`);
          });

          // console.log(`HERE: Users online: ${_.uniqBy(this.users, 'id').length} (${this.users.length} sessions)`, this.users);
        })
        .joining((user) => {
          // console.log(`${user.name} just joined`);
          
          this.users.push(user);

          // console.log(`JOINING: Users online: ${_.uniqBy(this.users, 'id').length} (${this.users.length} sessions)`, this.users);
        })
        .leaving((user) => {
          // console.log(`${user.name} just left`);

          for (let i = 0; i < this.users.length; i += 1) {
            if (this.users[i].id === user.id) {
              this.users.splice(i, 1);
              break;
            }
          }

          // console.log(`LEAVING: Users online: ${_.uniqBy(this.users, 'id').length} (${this.users.length} sessions)`, this.users);
        });    

        window.Echo.private(`online`).listenForWhisper('reporting', (e) => {
          // console.log(`online.whisper.received`, e);
        });

        window.Echo.private(`room-1`).listenForWhisper('dj', (e) => {
          // console.log(`room-1.whisper.dj`, e);

          switch(e.action) {
            case 'seek':
              // console.log(`room-1.whisperReceived.dj.seek`, e);
              Bus.$emit('track:seek-to', e.seekTime);
            break;

            case 'track':
              // console.log(`room-1.whisperReceived.dj.track`, e);
              
              if(_.isEqual(this.params, e.apiParams)) {
                Bus.$emit('music:auto-sync', e);
              } else {
                Bus.$emit('songs:fetch', e.apiParams);
              }
            break;

            case 'volume':
              // console.log(`room-1.whisperReceived.dj.track`, e);
              
              Bus.$emit('player:set-volume', e.volume);
            break;

            case 'button':
              // console.log(`room-1.whisperReceived.dj.track`, e);
              
              Bus.$emit('player:press-button', e.button);
            break;
          }
          
        });
      }
    },

    echoEvent(event) {
      // console.log('disco event received', event);

      if(event.dj && !this.isDJ()) {
        return;
      }

      switch (event.payload.message) {
        case 'load_playlist':
          Bus.$emit('songs:fetch', {
            playlist: event.payload.playlist,
          });
          break;

        case 'load_song':
          Bus.$emit('songs:fetch', {
            id: event.payload.songId,
            medium: '',
          });
          break;
      }      
    },
  },

  beforeDestroy () {
    // console.log('leaving disco channel');
    window.Echo.leave('online');
    window.Echo.leave('disco');
  },
};
