import { sync } from 'vuex-pathify';

export default {
  computed: {
    ...sync([
      'api/params', //
      'webamp/songs', //
    ]),
  },

  mounted() {
    Bus.$on('songs:fetch', (params) => {
      this.params = JSON.parse(JSON.stringify(params));

      if (this.isDJ()) {
        params.dj = 1;
      }

      // console.log('Bus.songs:fetch.params', params);

      this.$http
        .get('/api/v1/songs', {
          params,
        })
        .then((response) => {
          if (response.data.hash.length > 0) {
            window.location.hash = response.data.hash;
          }
          // console.log('song:fetch.response', response.data);
          this.songs = response.data.songs;
          Bus.$emit('songs:fetched', this.songs);
        });
    });
  },

  beforeDestroy() {
    Bus.$off('songs:fetch');
    Bus.$off('song-info:fetch');
  },
};
